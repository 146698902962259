/* eslint-disable max-lines-per-function */
import { ClipboardListIcon, ClockIcon } from "lucide-react";
import queryString from "query-string";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";

import NotFoundPage from "~/src/pages/not-found.jsx";

import EditProjectEditors from "~/src/features/edit-project-editors/index.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useProject from "~/src/hooks/use-project.js";
import useStore from "~/src/hooks/use-store.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import ProjectLocations from "~/src/ui/displays/project-locations/index.jsx";
import ProjectName from "~/src/ui/displays/project-name/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import ProjectIcon from "~/src/ui/icons/project-icon.jsx";
import TabLink from "~/src/ui/links/tab-link/index.jsx";
import Page from "~/src/ui/page/index.jsx";

import { PrioritizedCheckbox } from "./(_project)/_exports.js";
import {
  ProjectAttributesPage,
  ProjectCompaniesPage,
  ProjectContractsPage,
  ProjectDocumentsPage,
  ProjectInfosPage,
  ProjectOverviewPage,
  ProjectSettingsPage,
  ProjectTimelinePage,
  ProjectTransactionsPage,
  ProjectUnitsPage
} from "./project/_exports.js";

/**
 *
 * @example
 */
const ProjectPage = () => {
  const { id } = useParams();
  const { path, url } = useRouteMatch();
  const { state } = useLocation();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { isMod, user } = useAuth();
  const { editPossible } = useEdit({
    project,
    user
  });

  const savedQuery = useStore((state) => state.savedQuery);
  const history = useHistory();

  const goBackProjectsWithQuery = () => {
    const savedQueryString = queryString.stringify(savedQuery, {
      skipEmptyString: true,
      skipNull: true
    });

    history.push(`/projects?${savedQueryString}`);
  };

  // https://wsuwsu.atlassian.net/browse/IDEV-194
  const isErrorHackyFix = !isLoading && !project;

  if (isErrorHackyFix) {
    return <NotFoundPage status={isError?.status} />;
  }

  return (
    <Page title="Projektdetails">
      <PageHeader>
        <nav className="flex items-start justify-between">
          <button className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700" onClick={goBackProjectsWithQuery} type="button">
            Zurück zur Projektsuche
          </button>

          <div className="flex flex-col gap-2">
            <Link className="inline-flex items-center gap-1 text-sm font-medium text-gray-500 underline hover:text-gray-700" to={`/projects/${id}/project-contracts`}>
              <ClipboardListIcon className="size-5 text-gray-500" />

              <span>Kaufverträge</span>
            </Link>

            <Link className="inline-flex items-center gap-1 text-sm font-medium text-gray-500 underline hover:text-gray-700" to={`/activities?project=${id}`}>
              <ClockIcon className="size-5 text-gray-500" />

              <span>Aktivitäten</span>
            </Link>
          </div>

        </nav>

        <PrioritizedCheckbox />

        <div className="justify-between sm:flex">
          <div className="items-start sm:flex">
            {/* <ProjectIcon /> */}

            <div className="break-word mt-2 sm:mt-1">
              <h2 className="flex items-center gap-4 text-2xl font-bold text-gray-800 sm:text-2xl">
                <span className="rounded-md bg-gray-300 px-2 text-sm capitalize text-gray-900">ID: {project?.id}</span>

                <ProjectName isLoading={isLoading} project={project} state={state} />
              </h2>

              <ProjectLocations isLoading={isLoading} loadedProject={project} state={state} />
            </div>
          </div>

          <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:shrink-0 sm:justify-start">
            {!isLoading &&
              <EditProjectEditors projectId={id} />}
          </div>
        </div>
      </PageHeader>

      <MainContainer>
        <div className="mt-12">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex flex-wrap justify-center space-x-4 md:justify-start lg:space-x-8">
              <TabLink label="Übersicht" to={`${url}`} />

              <TabLink label="Allgemeine Daten" to={`${url}/project-infos`} />

              <TabLink label="Eigenschaften" to={`${url}/project-attributes`} />

              <TabLink label="Timeline" to={`${url}/project-timeline`} />

              <TabLink label="Dokumente" to={`${url}/project-documents`} />

              <TabLink label="Liegenschaftsankäufe" to={`${url}/project-transactions`} />

              <TabLink activeOnlyWhenExact={false} label="Objekte" to={`${url}/project-units`} />

              <TabLink label="Unternehmen" to={`${url}/project-companies`} />

              {(isMod && editPossible) ? <TabLink label="Einstellungen" to={`${url}/project-settings`} /> : null}
            </nav>
          </div>
        </div>

        <div className="mt-6 px-4 pt-6 md:px-0">
          <Switch>
            <Route exact path="/projects/:id">
              <ProjectOverviewPage />
            </Route>

            <Route path="/projects/:id/project-infos">
              <ProjectInfosPage />
            </Route>

            <Route path="/projects/:id/project-attributes">
              <ProjectAttributesPage />
            </Route>

            <Route path="/projects/:id/project-timeline">
              <ProjectTimelinePage />
            </Route>

            <Route path="/projects/:id/project-documents">
              <ProjectDocumentsPage />
            </Route>

            <Route path="/projects/:id/project-transactions">
              <ProjectTransactionsPage />
            </Route>

            <Route path="/projects/:id/project-companies">
              <ProjectCompaniesPage />
            </Route>

            <Route path="/projects/:id/project-units">
              <ProjectUnitsPage />
            </Route>

            <Route path="/projects/:id/project-settings">
              <ProjectSettingsPage />
            </Route>

            <Route path="/projects/:id/project-contracts">
              <ProjectContractsPage />
            </Route>

          </Switch>
        </div>

      </MainContainer>

    </Page>
  );
};

export default ProjectPage;
