import { useState } from "react";
import {
  useParams
} from "react-router-dom";

import useAuth from "../../hooks/use-auth.js";
import useProject from "../../hooks/use-project.js";
import API from "../../modules/api.js";
import CheckboxField from "../../ui/forms/checkbox-field/index.jsx";

const PrioritizedCheckbox = () => {
  const { id: projectId } = useParams();
  const {
    mutate,
    project: {
      prioritized
    } = {}
  } = useProject(projectId) ?? {};

  const { isMod } = useAuth();

  const [currentValue, setCurrentValue] = useState(prioritized);

  const handleChange = async (event) => {
    const value = event.target.checked;

    setCurrentValue(value);

    await API.patch(
      `/projects/${projectId}/prioritized`,
      {
        project: {
          prioritized: value
        }
      }
    );

    await mutate();
  };

  return (
    <CheckboxField
      compact
      className="flex w-auto"
      disabled={!isMod}
      errors={{}}
      innerClassName="flex gap-4"
      label="Priorisieren"
      name="prioritize"
      onChange={handleChange}
      register={() => {}}
      value={currentValue ?? prioritized ?? ""}
    />
  );
};

export default PrioritizedCheckbox;
